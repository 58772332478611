.App {
}
.form { 
  /* display: flex; */
  width: 50%;
}
.pdf { 
}

.dualForm {
  width: 54%;
  float: right;
  display: flex;
}